<template>
  <div class="wapper">
    <van-sticky>
      <div class="search-header ">
        <van-search v-model="value" show-action placeholder="输入关键字搜索" @search="onSearch" @cancel="onCancel">
          <template #left-icon>
            <img :src="require('@/assets/images/search/search.png')" class="icon">
          </template>
        </van-search>
      </div>
    </van-sticky>

    <!--    <div class="hot-search">-->
    <!--      <div class="search-title van-row&#45;&#45;flex van-cell&#45;&#45;center van-row&#45;&#45;justify-space-between">-->
    <!--        <span>热门搜索</span>-->
    <!--      </div>-->
    <!--      <div class="button-list van-row&#45;&#45;flex ">-->
    <!--        <van-button round type="info" color="#F7F8FA" class="button" @click="click"><span>公务员</span></van-button>-->
    <!--        <van-button round type="info" color="#F7F8FA" class="button" @click="click"><span>公务员</span></van-button>-->
    <!--        <van-button round type="info" color="#F7F8FA" class="button" @click="click"><span>公务员</span></van-button>-->
    <!--        <van-button round type="info" color="#F7F8FA" class="button" @click="click"><span>公务员</span></van-button>-->
    <!--        <van-button round type="info" color="#F7F8FA" class="button" @click="click"><span>公务员</span></van-button>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--热门搜索-->
    <div class="history-search" v-if="confirmValue===''">
      <div class="search-title van-row--flex van-cell--center van-row--justify-space-between"
        v-if="historySearchList.length>0">
        <span>历史搜索</span>
        <img :src="require('@/assets/images/search/del.png')" class="icon-del" @click="removeHistoryList">
      </div>
      <van-list>
        <van-cell v-for="item in historySearchList" :key="item" :title="item" center @click="onSearch(item)">
          <template #icon>
            <div class="icon">
              <img :src="require('@/assets/images/search/time.png')">
            </div>
          </template>
        </van-cell>
      </van-list>
    </div>

    <!--    搜索列表-->
    <courseList :list="courseList" :finished="finished" v-if="showCourseList" @load="onload" />

    <!--    空页面-->
    <van-empty :image="require('@/assets/images/empty/no_search.png')" description="没有找到相关内容" v-if="showEmpty" />

  </div>
</template>

<script>
import {getHistorySearch, pushHistorySearch, removeHistorySearch} from '@/cookies/search'
import {getSearchList} from '@/api/search'
import courseList from "@/components/course-list";
import commonMixins from "@/mixins/common";

export default {
  components: {
    courseList
  },
  mixins: [commonMixins],
  data() {
    return {
      value: '',
      confirmValue: '',
      loading: false,
      historySearchList: getHistorySearch(),
      courseList: [],
      finished: false,
      responding: false,
      page: 0,
      pageLength: 5
    };
  },
  computed: {
    showCourseList() {
      return this.confirmValue && this.courseList.length !== 0
    },
    showEmpty() {
      return this.confirmValue && this.courseList.length === 0
    }
  },
  watch: {
    value(val) {
      if (val === '') {
        this.confirmValue = ''
      }
    }
  },
  mounted() {
   this.$util.setShare()
  },
  methods: {
    onSearch(val) {
      this.resetSearchState()
      this.confirmValue = val
      this.apiGetSearchList(val)
      this.updateHistorySearchList()
    },
    onCancel() {
      window.history.go(-1)
      this.updateHistorySearchList()
    },
    updateHistorySearchList() {
      this.historySearchList = getHistorySearch()
    },
    removeHistoryList() {
      removeHistorySearch()
      this.historySearchList = []
    },
    resetSearchState() {
      this.page = 0
      this.finished = false
      this.courseList = []
    },

    apiGetSearchList(val) {

      this.$store.dispatch('getProvinceKey').then((province) => {
        let param = {}
        param.province = province
        param.keywords = val
        param.page = this.page + 1
        param.length = this.pageLength

        this.page = param.page
        this.responding = false
        pushHistorySearch(val)
        getSearchList(param).then((res) => {
          this.responding = true
          this.courseList = this.courseList.concat(res.data)

          if (res.data.length < this.pageLength) {
            this.finished = true
          }
        })
      })

    },
    onload() {
      this.apiGetSearchList()
    }
  },
}
</script>

<style scoped lang="scss">
.search-header {
  .van-search {
    padding-left: $containerPadding;
    padding-right: $containerPadding;
    box-sizing: border-box;
  }

  .van-search__action {
    padding-right: 0;
    padding-left: $containerPadding;
    font-size: 0.37rem;
    color: #919499;
  }

  .van-search__content {
    border-radius: 999px;
    overflow: hidden;
  }

  .van-search__action {
    color: #666;
    padding-left: .4rem;
  }

  .icon {
    width: .42rem;
    height: .42rem;
    margin-right: .15rem;
    transform: translateY(.05rem);
  }
}

.hot-search {
  background-color: #fff;
  padding: 0 $containerPadding;
  padding-bottom: $containerPadding;

  .button-list {
    .button {
      height: 0.85rem;
      font-size: 0.37rem;
      padding: 0 .64rem;
      margin-right: .32rem;
      margin-top: .43rem;

      .van-button__content {
        color: #22242E;
      }
    }
  }
}

.history-search {
  background-color: #fff;
  padding: 0 $containerPadding;
  border-top: .2rem solid #F7F8FA;

  .van-cell {
    padding: $containerPadding 0;
    font-size: 0.37rem;
    color: #22242E;
    line-height: 1;

    &::after {
      left: 0;
      width: 100%;
    }
  }

  .van-cell__title {
    padding-left: 0.08rem;
  }

  .icon {
    width: .35rem;
    height: .35rem;
    margin-right: .2rem;
  }

}

.search-title {
  font-size: 0.45rem;
  line-height: 0.64rem;
  color: #22242E;
  padding-top: .53rem;
  font-weight: bold;

  .icon-del {
    width: .59rem;
    height: auto;
  }
}
</style>
