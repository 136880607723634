<template>
  <div>
    <div class="course-card" @click="clickFn">
      <template v-if="course.cover_img">
        <div class="course-card-header van-row--flex">
          <div class="goods-image">
            <img :src="course.cover_img" alt="" class="image" />
          </div>
          <div class="van-cell__title book-title">
            <p class="title">{{ course.title }}</p>
            <p class="subtitle">{{ course.subtitle }}</p>
            <div class="lable-list">
              <span class="lable" v-for="(item, index) in tags" :key="index" :style="'color:' + item.text_color">{{
                  item.text
              }}</span>
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="course-card-header">
          <p class="title">{{ course.title }}</p>
          <p class="subtitle">{{ course.subtitle }}</p>
          <div class="lable-list">
            <span class="lable" v-for="(item, index) in tags" :key="index" :style="'color:' + item.text_color">{{
                item.text
            }}</span>
          </div>
          <div class="teacher-list">
            <template v-if="teacherInfo.length > 1">
              <div class="image" v-for="(item, index) in teacherInfo" :key="index">
                <img :src="item.portrait" class="portrait" v-if="index <= 2" />
              </div>
            </template>
            <template v-else-if="teacherInfo.length === 1">
              <div class="image">
                <img :src="teacherInfo[0].portrait" class="portrait" />
              </div>
              <div class="name">{{ teacherInfo[0].name }}</div>
            </template>
          </div>
        </div>
      </template>

      <div class="course-card-content">
        <div class="detail van-row--flex van-cell--center van-row--justify-space-between">
          <p class="text">已有{{ course.buy_num }}人拼团</p>
          <p class="price">
            <template v-if="course.is_buy">
              <span class="price-text">开始学习</span>
            </template>
            <template v-else>
              <span>拼团价</span>
              <span class="symbol">￥</span>
              <span class="number">{{
                  clearPriceZero(course.group_price)
              }}</span>
            </template>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isQQBrowser } from '@/utils/browser'

export default {
  props: ['course'],
  computed: {
    clearPriceZero () {
      return function (number) {
        return this.$util.clearPriceZero(number)
      }
    },
    tags () {
      return this.course.tags && JSON.parse(this.course.tags)
    },
    teacherInfo () {
      return this.course.teacher.info || []
    },
  },
  methods: {
    clickFn () {
      let id = this.course.id
      let optisons = { path: '/goods/detail', query: { id } }
      if (isQQBrowser()) {
        this.$util.locationHref(this.$util.getLocationURL(optisons)) //qq浏览器跳转
      } else {
        this.$router.push(optisons) //其他浏览器跳转
      }
    },
  },
  mounted () {
  },
}
</script>

<style scoped lang="scss">
.course-card {
  border-radius: 0.2rem;
  padding: 0.43rem 0.43rem 0;
  box-sizing: border-box;
  background: #fff;
  margin-top: 0.43rem;

  .title {
    @include ellipsis(2);
    font-size: 0.43rem;
    line-height: 0.59rem;
    color: #22242e;
    margin-bottom: 0.15rem;
  }

  .subtitle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 0.32rem;
    color: #919499;
    margin-bottom: 0.2rem;
  }

  .goods-image {
    width: 2.67rem;
    height: 2.67rem;
    margin-right: 0.32rem;

    .image {
      width: 90%;
      height: 90%;
      margin-top: 5%;
    }
  }

  .lable-list {
    margin-bottom: 0.43rem;
    font-size: 0;
    overflow: hidden;

    .lable {
      @include ellipsis();
      max-width: 4rem;
      display: inline-block;
      height: 0.64rem;
      line-height: 0.64rem;
      font-size: 0.29rem;
      color: #448aff;
      background: #effaff;
      border-radius: 0.08rem;
      margin-right: 0.2rem;
      padding: 0 0.16rem;
    }
  }

  .teacher-list {
    display: flex;
    align-items: center;
    margin-bottom: 0.43rem;

    .image {
      width: 0.68rem;
      height: 0.68rem;
      border-radius: 999px;
      overflow: hidden;
      margin-right: 0.16rem;

      .portrait {
        width: 100%;
        height: 100%;
      }
    }

    .name {
      font-size: 0.32rem;
      color: #919499;
      margin-left: 0.08rem;
    }
  }

  .detail {
    border-top: 1px solid #f7f8fa;
    margin-top: 0.2rem;
    height: 1rem;
    font-size: 0;

    .price {
      font-size: 0.32rem;
      color: #ff6a4d;

      .symbol {
        font-size: 0.37rem;
        font-weight: bold;
        font-family: 'DIN-Medium';
        vertical-align: middle;
        transform: translateY(-0.03rem);
        display: inline-block;
        line-height: 0;
      }

      .number {
        font-size: 0.53rem;
        font-family: 'DIN-Medium';
        display: inline-block;
        vertical-align: middle;
        transform: translateY(-0.05rem);
        line-height: 0;
      }

      .price-text {
        font-size: 0.37rem;
        font-weight: bold;
      }
    }

    .text {
      font-size: 0.32rem;
      color: #919499;
    }
  }
}

.book-title {
  .lable-list {
    margin-bottom: 0;
  }
}
</style>
