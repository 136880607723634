import Cookies from 'js-cookie'

const HistoryKey = 'Search-History'

export function getHistorySearch() {
    let result = Cookies.get(HistoryKey)
    return (result && result.split(','))||[]
}

export function setHistorySearch(text) {
    return Cookies.set(HistoryKey, text, {expires: 7})
}

export function pushHistorySearch(text) {
    if (!text) return;
    let result = getHistorySearch() || ''
    result += result.length > 0 ? ',' + text : 'text'
    return setHistorySearch(result)
}

export function removeHistorySearch() {
    return Cookies.remove(HistoryKey)
}
