<template>
  <div>
    <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="list.length===0?'':''"
        @load="onLoad"
    >
      <div class="course-list">
        <courseListCard v-for="(item,index) in list" :key="index" :course="item"/>
      </div>
      <pageEmptyDefault :icon="require('@/assets/images/empty/no_course.png')" text="暂无课程"
                        v-if="list.length===0&&finished"/>
    </van-list>

  </div>
</template>

<script>
import courseListCard from '@/components/course-list-card'
import pageEmptyDefault from '@/components/page-empty-default'

export default {
  props: ['list', 'finished','loading'],

  components: {
    courseListCard,
    pageEmptyDefault
  },

  methods: {

    onLoad() {
        this.$emit('loads')
    },
  },
};
</script>

<style scoped lang="scss">
.course-list {
  padding: 0 $containerPadding;
  padding-bottom: $containerPadding;
}

</style>
