import request from '@/utils/request'

// 获取搜索列表
export function getSearchList(data) {
    return request({
        url: '/home/search',
        method: 'post',
        data: data,
    })
}
